/* This wrapper is used for compatibility with the old widget. */

/* global IPv6Widget */

window.CZNIC_IPV6_WIDGET_DIST = {
    load_widget(options) {
        const ownUrl = document.querySelector("script[src$='cznicwidget.js']").src.replace("cznicwidget.js", "");
        const script = document.createElement("script");
        script.src = ownUrl + "ipv6widget.js";
        document.head.appendChild(script);

        script.onload = function () {
            const newOptions = {};
            newOptions.element = `#${options.target_id}`;

            if (options.language) {
                newOptions.language = options.language;
            }

            newOptions.skin = "cz.nic";

            if (document.location.hostname === "www.nic.cz") {
                newOptions.tests = ["dnssec", "ipv6", "fenix"];
            } else {
                newOptions.tests = ["dnssec", "ipv6"];
            }

            newOptions.speed = ["ipv4", "ipv6"];
            newOptions.url = ownUrl;

            new IPv6Widget(newOptions);
        };
    }
};
